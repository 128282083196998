const Checkbox = ({ id, name, value, checked, disabled, onChange, children }) => {
  return (
    <label className="cursor-pointer flex gap-2.5">
      <input
        id={id}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className="appearance-none focus:ring-secondary transition-colors h-4.5 w-4.5 text-purple-450 border-white-700 rounded my-0.5 cursor-pointer"
      />
      <span className="text-base leading-20 text-dark-400 font-base font-medium">{children}</span>
    </label>
  )
}

export default Checkbox
