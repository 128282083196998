const NavigationLink = ({ children, href, name, target }) => {
  return (
    <a
      href={href}
      target={target}
      rel={target ? 'noreferrer' : undefined}
      className="flex items-center w-full cursor-pointer text-white py-2 px-4 rounded-10 hover:bg-secondary text-white-100 hover:text-white transition-colors"
    >
      <span className="mr-4 flex-shrink-0 h-6 w-6 text-clear">{children}</span>
      <span className="text-base leading-24 font-medium font-base truncate flex-1 text-left">
        {name}
      </span>
    </a>
  )
}

export default NavigationLink
