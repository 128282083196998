import classNames from 'classnames'

const Btn = ({ children, href, target, row, variant, outline, disabled, onClick, full }) => {
  const btnClassNames = classNames([
    'rounded-lg py-2.25 border border-solid text-sm leading-20 font-medium font-title tracking-0.5 transition-colors transition-all',
    'focus:outline-none focus:ring-2 focus:ring-offset-2',
    {
      'inline-block px-5 text-center': !row,
      'inline-flex items-center justify-center px-3 gap-2': row,
      'bg-secondary hover:bg-opacity-10 border-secondary focus:ring-secondary text-white hover:text-primary':
        !variant && !outline,
      'bg-secondary bg-opacity-10 border-secondary hover:border-primary-800 border-opacity-10 focus:ring-secondary text-primary-800':
        !variant && outline,
      'bg-white hover:bg-dark hover:bg-opacity-10 border-opacity-7 border-dark hover:border-opacity-100 text-dark focus:ring-dark':
        variant === 'secondary' && outline,

      'cursor-pointer': !disabled,
      'w-full max-w-full': full
    }
  ])

  if (href) {
    return (
      <a
        href={href}
        target={target}
        rel={target ? 'noreferrer' : undefined}
        className={btnClassNames}
      >
        {children}
      </a>
    )
  }

  return (
    <button type="button" className={btnClassNames} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  )
}

export default Btn
