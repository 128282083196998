import React from 'react'
import { useSelector } from 'react-redux'
import MarkdownPreview from './MarkdownPreview'
import BoxCard from './box-card/BoxCard'
import classNames from 'classnames'
import Btn from './Btn'

export const Banner = ({ banner, editBanner }) => {
  const { currentUser } = useSelector((state) => state.auth)
  return (
    <BoxCard>
      <div className="md:-mb-1 pt-4 md:pt-1">
        {currentUser.role === 'admin' && (
          <div className="pb-4 text-right">
            <Btn onClick={() => editBanner(banner)}>
              {'Edit ' + capitalizeFirstLetterOfEveryWord(banner.type)}
            </Btn>
          </div>
        )}
        <div className="flex flex-wrap items-center gap-7.5 xl:gap-10">
          <div
            className={classNames([
              'flex-1 xl:pl-1.75',
              {
                'text-center': !banner.video_url
              }
            ])}
          >
            <h2 className="text-md-3 leading-30 text-dark font-semibold font-base tracking-1 mb-1.5">
              {banner.title}
            </h2>
            <MarkdownPreview markdown={banner.content} />
            <div className="mt-7.5">
              <Btn href={banner.button_url} target="_blank">
                {banner.button_text}
              </Btn>
            </div>
          </div>
          {banner.video_url !== '' && (
            <div className="w-full sm:w-335">
              <div className="relative" style={{ paddingTop: '55.25%' }}>
                <iframe
                  title={banner.video_url}
                  className="absolute top-0 left-0 right-0 bottom-0 w-full max-w-full h-full max-w-full border border-solid border-primary border-opacity-10 rounded-10 overflow-hidden"
                  src={banner.video_url}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    </BoxCard>
  )
}

function capitalizeFirstLetterOfEveryWord(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
