import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import FormField from '../../utils/FormField'
import { upsertBanner } from './dashboardSlice'
import SecondaryButton from '../../components/general/SecondaryButton'
import LoadingSpinner from '../../components/general/LoadingSpinner'

const MDEditor = React.lazy(() => import('@uiw/react-md-editor'))

const DashboardBannerForm = ({ setState, setOpen, editingItem }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({})

  useEffect(() => {
    if (editingItem?.id || editingItem?.type) {
      setFormValues({ ...editingItem })
    } else {
      setFormValues({})
    }
  }, [editingItem])

  const onChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    setFormValues((prevState) => ({ ...prevState, [name]: value }))
  }

  const isPremiumType = editingItem?.type === 'premium';

  return (
    <div>
      <h3 className="text-2xl">
        {formValues?.id ? `Edit ${formValues.type} banner: ` : `Add ${formValues.type} banner`}
      </h3>

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          const upsertResult = await dispatch(upsertBanner({ banner: formValues }))

          console.log(upsertResult.type)
          if (upsertResult.type.includes('fulfilled')) {
            setOpen(false)
          }
        }}
      >
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <div className="mt-1">
              <FormField label="Title" field="title" onChange={onChange} state={formValues} />
            </div>
          </div>
          <div className="sm:col-span-6">
            <div className="mt-1">
              <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
                Content
              </label>
              <Suspense fallback={<LoadingSpinner />}>
                <MDEditor
                  id="content"
                  name="content"
                  value={formValues?.content || ''}
                  onChange={(value) =>
                    setFormValues((prevState) => ({
                      ...prevState,
                      content: value
                    }))
                  }
                  hideToolbar={false}
                  preview="edit"
                  className={`text-lg border-2 rounded-md hover:border-purple-450`}
                  visiableDragbar={true}
                />
              </Suspense>
            </div>
          </div>
          <div className="sm:col-span-6">
            <div className="mt-1">
              <FormField
                label="Video Url"
                field="video_url"
                onChange={onChange}
                state={formValues}
                required={false}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Button Text"
                field="button_text"
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <div className="mt-1">
              <FormField
                label="Button Url"
                field="button_url"
                onChange={onChange}
                state={formValues}
              />
            </div>
          </div>
          {isPremiumType && (
            <div className="sm:col-span-3">
              <div className="mt-1">
                <FormField
                  label="Current Average Shipping Rate"
                  field="average_shipping_rate"
                  onChange={onChange}
                  state={formValues}
                />
              </div>

            </div>
          )}
        </div>

        <div className="mt-12 py-3 flex flex-row space-x-4 sm:justify-between">
          <div className="w-1/3">
            {editingItem?.id && (
              <button
                type="button"
                className="uppercase w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1.5 sm:py-2 bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto"
                onClick={() => setState('delete')}
              >
                Delete
              </button>
            )}
          </div>
          <div className="w-2/3 flex flex-1 space-x-4 sm:flex-grow-0 items-start">
            <PrimaryButton extraClasses="uppercase px-8" label="Save" type="submit" />

            <SecondaryButton
              label="Cancel"
              extraClasses="uppercase"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default DashboardBannerForm
