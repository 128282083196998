import React from 'react';

const ChatBase = () => {
  return (<>
    <iframe
      title="chatbot"
      src="https://www.chatbase.co/chatbot-iframe/pNsb-ekwEwfKxcAE2Hn-l"
      width="100%"
      className="ai-tool-iframe"
    />
  </>);
}

export default ChatBase
