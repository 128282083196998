import React from 'react'

const ResourceSearch = ({ search, setSearch }) => {
  return (
    <div>
      <div className="relative rounded-md">
        <div className="absolute inset-y-0 left-0 pl-3.5 flex items-center pointer-events-none text-dark-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <input
          type="search"
          name="search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          className="text-base sm:text-base leading-24 text-dark font-normal font-base tracking-0.5 placeholder-dark-500 border border-solid border-white-700 bg-white focus:ring-secondary focus:border-secondary block w-full pl-9 py-1.75 pr-3.5 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search"
        />
      </div>
    </div>
  )
}

export default ResourceSearch
