import { Link } from 'react-router-dom'

const BoxCardHeader = ({ children, title, link }) => {
  if (children || title || link) {
    return (
      <div className="flex flex-wrap gap-2.5 items-center pb-3.5">
        {title ? (
          <div className="flex-1 max-w-full overflow-hidden order-first sm:order-none">
            <h4
              title={title}
              className="text-md leading-22 text-dark font-title font-medium tracking-1 truncate"
            >
              {title}
            </h4>
          </div>
        ) : null}
        {children}
        {link ? (
          <div className="flex-shrink-0 flex-grow-0 self-end -mt-2.5 -mr-2.5 order-2 sm:order-none">
            <Link
              to={link}
              className="inline-flex justify-center items-center w-8 h-8 text-dark hover:text-secondary transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M5.36365 12.6364L12.6364 5.36363"
                  stroke="currentColor"
                  strokeWidth="1.45455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.36365 5.36363H12.6364V12.6364"
                  stroke="currentColor"
                  strokeWidth="1.45455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

export default BoxCardHeader
