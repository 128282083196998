import React, { useState } from 'react'
import { ArrowSmUpIcon, ArrowSmDownIcon, PencilAltIcon } from '@heroicons/react/outline'
import { getIsResourceOwned } from './Resources'
import Btn from './../../components/general/Btn'
import classNames from 'classnames'

const ResourceItem = ({ resource, currentUser, onClick, onClickResourceEdit }) => {
  const [isReadingMore, setIsReadingMore] = useState(false)
  const isDescriptionLong = resource.description.length >= 80
  const shortenedDescription = resource.description.substring(0, 80)

  return (
    <div className="flex flex-col h-full cursor-pointer relative">
      <div
        className="relative rounded-t-2xl overflow-hidden bg-secondary group bg-opacity-5"
        style={{ paddingTop: '72.25%' }}
        onClick={onClick}
      >
        <div
          className="absolute top-0 left-0 h-full w-full bg-cover bg-no-repeat bg-center p-2.5"
          style={{
            backgroundImage: resource.coverPhotoUrl ? 'url(' + resource.coverPhotoUrl + ')' : null
          }}
        >
          <div className="flex">
            {resource.visibility.includes('For Sale') ? (
              <span className="bg-secondary rounded-lg py-1.5 px-3 text-xs leading-18 font-base font-medium text-white">
                Paid Upgrade
                {getIsResourceOwned(resource, currentUser) ? ' · OWNED' : null}
              </span>
            ) : (
              <div />
            )}
            <span className="ml-auto bg-white w-8 h-8 flex items-center justify-center rounded-lg text-dark group-hover:text-secondary transition-colors">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M5.36365 12.6364L12.6364 5.36363"
                  stroke="currentColor"
                  strokeWidth="1.45455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.36365 5.36363H12.6364V12.6364"
                  stroke="currentColor"
                  strokeWidth="1.45455"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {/* {'Link' === resource.type && <LinkIcon className="w-5 h-5 mr-1" />}
                {'Article' === resource.type && <PaperClipIcon className="w-5 h-5 mr-1" />}
                {'Course' === resource.type && <BookOpenIcon className="w-5 h-5 mr-1" />}
                {resource.type} */}
            </span>
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col py-4 px-4.5 rounded-b-2xl border-b border-l border-r border-solid border-primary border-opacity-20 bg-white overflow-hidden">
        <h4
          onClick={onClick}
          className="text-md-2 leading-25 text-dark hover:text-secondary font-semibold font-title tracking-1 transition-colors"
        >
          {/* <span aria-hidden="true" className="absolute inset-0" /> */}
          {resource.title}
        </h4>
        {/* {new Date(resource.publishedAt).toLocaleDateString()} */}
        <p
          className={classNames([
            'text-base leading-24 font-base font-normal text-dark-400 mt-1 break-words',
            {
              'line-clamp-2': !isReadingMore
            }
          ])}
        >
          <span>
            {isDescriptionLong && !isReadingMore ? shortenedDescription : resource.description}
          </span>
        </p>
        {resource.description || 'admin' === currentUser?.role ? (
          <div className="pt-4 mt-auto flex flex-wrap gap-2.5 relative z-1">
            {resource.description.length >= 80 && (
              <div className="flex-1">
                <Btn
                  onClick={() => setIsReadingMore((val) => !val)}
                  row={true}
                  outline={true}
                  full={true}
                >
                  <span className="whitespace-nowrap">Read {isReadingMore ? 'Less' : 'More'}</span>
                  {isReadingMore ? (
                    <ArrowSmUpIcon className="h-4 w-4" />
                  ) : (
                    <ArrowSmDownIcon className="h-4 w-4" />
                  )}
                </Btn>
              </div>
            )}

            {'admin' === currentUser?.role && (
              <div className="flex-1">
                <Btn
                  onClick={() => onClickResourceEdit(resource)}
                  variant="secondary"
                  outline={true}
                  row={true}
                  full={true}
                >
                  <span className="whitespace-nowrap">Edit</span>
                  <PencilAltIcon className="w-4 h-4" />
                </Btn>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ResourceItem
