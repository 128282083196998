import { Link } from 'react-router-dom'
import classNames from 'classnames'

const NavigationItem = ({ item, currentUser, onNavigate }) => {
  const { requiredRoles, href, name, icon: Icon, current, onClick } = item
  const isPublicNavItem = requiredRoles.includes('public')
  const userHasAccessToArea = currentUser && requiredRoles.includes(currentUser?.role)

  const className = classNames(
    'flex items-center w-full cursor-pointer text-white py-2 px-4 rounded-10 hover:bg-secondary hover:text-white transition-colors',
    {
      'text-white-100': !current,
      'text-white bg-secondary': current
    }
  )

  const classNameTitle = 'text-base leading-24 font-medium font-base truncate flex-1 text-left'

  if (!isPublicNavItem && !userHasAccessToArea) return null

  return !onClick ? (
    <Link to={href} role="link" className={className} onClick={onNavigate}>
      <Icon className="mr-4 flex-shrink-0 h-6 w-6" />
      <span className={classNameTitle}>{name}</span>
    </Link>
  ) : (
    <button role="link" className={className} onClick={onClick}>
      <Icon className="mr-4 flex-shrink-0 h-6 w-6" />
      <span className={classNameTitle}>{name}</span>
    </button>
  )
}

export default NavigationItem
