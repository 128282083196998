const ProgressBar = ({ count }) => {
  return (
    <div className="flex items-center gap-3">
      <div className="flex-1 rounded bg-white-300">
        <div className="h-2 rounded bg-secondary" style={{ width: count + '%' }} />
      </div>
      <span className="flex-initial text-sm leading-20 text-dark font-bold font-secondary">
        {count + '%'}
      </span>
    </div>
  )
}

export default ProgressBar
