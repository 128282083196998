import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/general/PrimaryButton'
import SecondaryButton from '../../components/general/SecondaryButton'
import LessonSearch from './LessonSearch'
import {
  ClipboardCheckIcon,
  PencilIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/solid'
import {
  deleteLessonAsync,
  deleteModuleAsync,
  getCompletedLessonsAsync,
  markLessonAsCompletedAsync,
  markLessonAsUncompletedAsync,
  readLessonOptionsAsync,
  setActiveLesson,
  setActiveModule,
  useCourseModules
} from './courseSlice'
import FormModal from '../../components/general/FormModal'
import ModuleForm from './ModuleForm'
import LessonForm from './LessonForm'
import MarkdownPreview from '../../components/general/MarkdownPreview'
import ReorderModulesForm from './ReorderModulesForm'
import { Transition } from '@headlessui/react'
import { useHistory, useParams } from 'react-router-dom'
import TypeformEmbed from './TypeformEmbed'
import { matchSubStringIgnoreUnicode } from '../../utils/matchSubStringIgnoreUnicode'
import Btn from '../../components/general/Btn'

const Course = () => {
  const history = useHistory()
  const { moduleId, lessonId } = useParams()
  const dispatch = useDispatch()
  const { loading, activeModule, activeLesson, completedLessons } = useSelector(
    (state) => state.course
  )
  const { modules } = useCourseModules()

  const { currentUser } = useSelector((state) => state.auth)
  const [moduleModalOpen, setModuleModalOpen] = useState(false)
  const [editingModule, setEditingModule] = useState(null)

  const [hideActiveModule, toggleHideActiveModule] = useReducer((state) => !state, false)

  const [lessonModalOpen, setLessonModalOpen] = useState(false)
  const [editingLesson, setEditingLesson] = useState(null)

  const [reorderLessonsModulesModalOpen, setReorderLessonsModulesModalOpen] = useState(false)

  const nextLesson = getNextLesson(activeLesson, modules)

  useEffect(() => {
    dispatch(getCompletedLessonsAsync())
  }, [dispatch])

  useEffect(() => {
    if (!activeLesson || !activeLessonValid(activeLesson, modules)) {
      dispatch(setActiveLesson({ lesson: getFirstLesson(modules) }))
    }
  }, [dispatch, modules, activeLesson])

  const [isLessonCompleted, setIsLessonCompleted] = useState(false)

  useEffect(() => {
    if (activeLesson) {
      setIsLessonCompleted(completedLessons?.find((lesson) => lesson.id === activeLesson.id))
    }
  }, [completedLessons, activeLesson])

  // search
  const [modulesFiltered, setModulesFiltered] = useState(modules) // for the module search
  const [search, setSearch] = useState('')

  // search filter
  useEffect(() => {
    let modulesTemp
    modulesTemp = modules
      .map((module) => {
        let moduleCopy = Object.assign({}, module)
        if (matchSubStringIgnoreUnicode(moduleCopy.title, search)) return moduleCopy
        moduleCopy.Lessons = moduleCopy.Lessons.filter((lesson) =>
          matchSubStringIgnoreUnicode(lesson.title, search)
        )
        if (0 === moduleCopy.Lessons.length) return null
        return moduleCopy
      })
      .filter((moduleCopy) => moduleCopy !== null)
    setModulesFiltered(modulesTemp)
  }, [search, modules])

  useEffect(() => {
    if (!lessonId && modules && modules[0] && !activeModule) {
      history.push(`/course/${modules[0].id}/${modules[0]?.Lessons[0]?.id}`)
      dispatch(setActiveModule({ module: modules[0] }))
    }
    // eslint-disable-next-line
  }, [modules])

  useEffect(() => {
    const numberLessonId = parseInt(lessonId, 10)
    const numberModuleId = parseInt(moduleId, 10)

    if (modules.length && moduleId && lessonId) {
      const _module = modules.find((mod) => mod.id === numberModuleId)

      const lesson = _module?.Lessons.find((les) => les.id === numberLessonId)

      dispatch(setActiveModule({ module: _module }))
      dispatch(setActiveLesson({ lesson }))
    }
  }, [dispatch, lessonId, moduleId, modules])

  useEffect(() => {
    if (currentUser?.role === 'admin') dispatch(readLessonOptionsAsync())
  }, [dispatch, currentUser.role])

  return (
    <div className="h-screen py-6">
      <div className="container">
        <div className="py-4 flex flex-col-reverse lg:flex-row lg:gap-4 items-center lg:items-start">
          <div className="mt-8 lg:mt-0 max-w-full lg:px-6 lg:max-w-xs xl:max-w-sm lg:px-0">
            <h2 id="course-modules" className="text-2xl font-light mb-2 lg:hidden">
              Course Modules
            </h2>
            <LessonSearch search={search} setSearch={setSearch} />
            <HiddenDivWithExtraTailwindClasses />
            <div className="mt-4 select-none">
              <ul>
                {modulesFiltered?.map((module) => (
                  <li key={module.id} className="mb-4">
                    <div
                      onClick={() => {
                        if (module.id !== activeModule.id) {
                          dispatch(setActiveModule({ module }))
                          if (hideActiveModule) toggleHideActiveModule()
                          return
                        }
                        toggleHideActiveModule()
                      }}
                      className={`${
                        activeModule?.id === module.id ? 'bg-gray-300' : 'bg-gray-200'
                      } flex justify-between items-center cursor-pointer rounded-t-md`}
                    >
                      <h2 className="p-3 font-light flex flex-start text-md">
                        {module.title}
                        {'admin' === currentUser?.role && (
                          <PrimaryButton
                            label={
                              <span className="flex items-center text-xs">
                                <PencilIcon className="h-4 w-4" />
                              </span>
                            }
                            extraClasses="ml-4 uppercase px-2 py-0.5 max-h-6"
                            size="sm"
                            onClick={() => {
                              setEditingModule(module)
                              setModuleModalOpen(true)
                            }}
                          />
                        )}
                      </h2>
                      <div className="mr-3">
                        {!hideActiveModule && activeModule?.id === module.id ? (
                          <ChevronUpIcon className="h-8 w-8" />
                        ) : (
                          <ChevronDownIcon className="h-8 w-8" />
                        )}
                      </div>
                    </div>
                    <Transition
                      show={!hideActiveModule && activeModule?.id === module.id}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <ul>
                        {module?.Lessons?.map((lesson) => (
                          <li
                            key={lesson.id}
                            className={`${
                              activeLesson?.id === lesson.id
                                ? 'bg-purple-50 text-purple-450'
                                : 'bg-gray-50 text-black hover:bg-gray-100'
                            } py-3 px-6 font-light border-b cursor-pointer flex justify-between`}
                            onClick={() => {
                              document.getElementById('main').scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                              setEditingLesson({
                                ...lesson
                              })

                              history.push(`/course/${module.id}/${lesson.id}`)

                              dispatch(setActiveLesson({ lesson }))
                            }}
                          >
                            <span>{lesson.title}</span>

                            <span>
                              {completedLessons?.find((l) => l.id === lesson.id) && (
                                <span className="text-sm p-0.5 px-1 border text-purple-450 border-purple-450 rounded-md">
                                  completed
                                </span>
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </Transition>
                  </li>
                ))}
              </ul>
              {'admin' === currentUser?.role && (
                <div className="flex flex-col space-y-1">
                  <PrimaryButton
                    label="+ Add New Module"
                    extraClasses="mt-4 uppercase"
                    onClick={() => {
                      setEditingModule(null)
                      setModuleModalOpen(true)
                    }}
                  />
                  <PrimaryButton
                    label="+ Add New Lesson"
                    extraClasses="mt-4 uppercase"
                    onClick={() => {
                      setEditingLesson(null)
                      setLessonModalOpen(true)
                    }}
                  />
                  <PrimaryButton
                    label="Reorder Modules"
                    extraClasses="mt-4 uppercase"
                    onClick={() => {
                      setReorderLessonsModulesModalOpen(true)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex-1 py-12 px-6 bg-purple-100 rounded-md overflow-hidden">
            {activeLesson && (
              <>
                <div className="flex flex-wrap items-center gap-6">
                  <h1 className="flex-1 flex-shrink text-4xl text-secondary font-light">
                    {activeLesson.title}
                  </h1>
                  {'admin' === currentUser?.role && (
                    <div className="ml-auto flex-shrink-0">
                      <Btn
                        onClick={() => {
                          setEditingLesson(activeLesson)
                          setLessonModalOpen(true)
                        }}
                        row={true}
                      >
                        <PencilIcon className="h-4 w-4" />
                        <span>Edit Lesson</span>
                      </Btn>
                    </div>
                  )}
                </div>

                <TypeformEmbed lesson={activeLesson} />

                <div className="mt-6 bg-purple-100" data-color-mode="light">
                  <MarkdownPreview markdown={activeLesson.content} />
                </div>
                <div className="mt-12 flex flex-col space-y-4">
                  <PrimaryButton
                    disabled={loading}
                    onClick={() => {
                      isLessonCompleted
                        ? dispatch(markLessonAsUncompletedAsync({ lessonId: activeLesson.id }))
                        : dispatch(
                            markLessonAsCompletedAsync({
                              lessonId: activeLesson.id
                            })
                          )
                    }}
                    label={
                      <span className="flex items-center">
                        {isLessonCompleted ? (
                          <>
                            <ClipboardCheckIcon className="w-5 h-5 mr-2" />
                            Mark as Uncompleted
                          </>
                        ) : (
                          <>
                            <span className="w-4 h-4 mr-3 border border-white"></span>
                            Mark as Completed
                          </>
                        )}
                      </span>
                    }
                    extraClasses="uppercase shadow"
                  />
                  {nextLesson && (
                    <SecondaryButton
                      onClick={() => {
                        dispatch(setActiveLesson({ lesson: nextLesson }))
                        history.push(`/course/${nextLesson.ModuleId}/${nextLesson.id}`)
                      }}
                      label="Next Lesson"
                      extraClasses="w-full bg-white shadow uppercase"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <FormModal
        open={moduleModalOpen}
        setOpen={setModuleModalOpen}
        Form={ModuleForm}
        editingItem={editingModule}
        setEditingItem={setEditingModule}
        deleteAction={async () => {
          await dispatch(deleteModuleAsync({ module: editingModule }))
        }}
      />
      <FormModal
        open={lessonModalOpen}
        setOpen={setLessonModalOpen}
        Form={LessonForm}
        editingItem={editingLesson}
        setEditingItem={setEditingLesson}
        deleteAction={async () => {
          await dispatch(deleteLessonAsync({ lesson: editingLesson }))
        }}
      />
      <FormModal
        open={reorderLessonsModulesModalOpen}
        setOpen={setReorderLessonsModulesModalOpen}
        Form={ReorderModulesForm}
      />
    </div>
  )
}

const HiddenDivWithExtraTailwindClasses = () => {
  // this element is used to solve the problem of tailwind classes used in lessons missing from the compiled css
  // lessons descriptions are in markdown, which can contain html, which can contain tailwind classes
  // the lessons markdown is stored in the DB, not in the codebase, so the tailwind classes will not be included in the css output by the build command unless they are used in the code
  return (
    <div className="hidden aspect-w-16 aspect-w-1 aspect-w-3 aspect-w-4 aspect-h-9 aspect-h-1 aspect-h-3 aspect-h-4" />
  )
}

function getFirstLesson(modules) {
  let found = null
  modules.forEach((module) =>
    module.Lessons.forEach((lesson) => {
      if (!found) found = lesson
    })
  )
  return found
}

function getNextLesson(activeLesson, modules) {
  if (!activeLesson) return null
  let found = false
  let nextLesson = null
  modules.forEach((module) =>
    module.Lessons.forEach((l) => {
      if (found && !nextLesson) nextLesson = l
      if (l.id === activeLesson.id) found = true
    })
  )
  return nextLesson
}

function activeLessonValid(activeLesson, modules) {
  let found
  modules.forEach((module) =>
    module.Lessons.forEach((lesson) => {
      if (lesson.id === activeLesson?.id) found = lesson
    })
  )
  return found ? true : false
}

export default Course
