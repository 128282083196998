import { PencilAltIcon, PlusIcon } from '@heroicons/react/outline'
import React from 'react'
import { useSelector } from 'react-redux'
import ResourceSearch from './ResourceSearch'
import Checkbox from '../../components/general/form/Checkbox'
import Radio from '../../components/general/form/Radio'

const ResourcesFilters = ({
  setResourceCategoryModalOpen,
  setEditingResourceCategory,
  viewingResource,
  onChangeFilters,
  filters,
  search,
  setSearch
}) => {
  const { currentUser } = useSelector((state) => state.auth)
  const { resourceCategories: categories } = useSelector((state) => state.resource)

  function onChangeType(event) {
    onChangeFilters({ ...filters, type: event.target.id })
  }

  function onToggleCategory(event, categoryId) {
    const categories = event.target.checked
      ? [...filters.categories, categoryId]
      : filters.categories.filter((c) => c !== categoryId)
    onChangeFilters({ ...filters, categories })
  }

  function onAddCategory() {
    setEditingResourceCategory({})
    setResourceCategoryModalOpen(true)
  }

  function onEditCategory(category) {
    setEditingResourceCategory(category)
    setResourceCategoryModalOpen(true)
  }

  if (viewingResource) return null

  return (
    !viewingResource && (
      <div>
        <div className="mb-6">
          <ResourceSearch search={search} setSearch={setSearch} />
        </div>

        <div className="mb-6 space-y-2">
          <Radio
            id="all"
            name="type"
            value="all"
            checked={'all' === filters?.type}
            onChange={onChangeType}
          >
            All Resources
          </Radio>
          <Radio
            id="marketplace"
            name="type"
            checked={'marketplace' === filters?.type}
            value="marketplace"
            onChange={onChangeType}
          >
            AMarketplace (Paid Upgrades)
          </Radio>
          <Radio
            id="my-purchases"
            name="type"
            value="my-purchases"
            checked={'my-purchases' === filters?.type}
            onChange={onChangeType}
          >
            My Purchases
          </Radio>
        </div>

        <ResourceCategories
          categories={categories}
          onAddCategory={onAddCategory}
          onEditCategory={onEditCategory}
          onToggleCategory={onToggleCategory}
          filters={filters}
          isAdmin={currentUser?.role === 'admin'}
        />
      </div>
    )
  )
}

function ResourceCategories({
  filters,
  onToggleCategory,
  categories,
  onAddCategory,
  onEditCategory,
  isAdmin
}) {
  return (
    <>
      <div className="flex flex-wrap justify-between items-center gap-2.5">
        <h3 className="text-md leading-22 text-dark font-title font-semibold tracking-1">
          Categories
        </h3>
        {isAdmin && (
          <button
            onClick={onAddCategory}
            className="flex items-center justify-center gap-1.5 p-1.5 text-xs-2 font-title font-title leading-20 tracking-0.5 text-grey hover:bg-dark hover:bg-opacity-10 rounded-lg border border-solid border-grey border-opacity-20 focus:outline-none focus:border-dark"
          >
            <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 rounded bg-white border border-solid border-grey border-opacity-20">
              <PlusIcon className="w-4 h-4" />
            </span>
            <span>Add Category</span>
          </button>
        )}
      </div>
      <div className="mt-3 space-y-2">
        {categories.map((category) => (
          <div key={category.id} className="relative flex items-center justify-between gap-2.5">
            <Checkbox
              id={`category-${category.id}`}
              name="category"
              type="checkbox"
              value={category.id}
              checked={filters?.categories.includes(category.id)}
              onChange={(event) => onToggleCategory(event, category.id)}
            >
              {category.name}
            </Checkbox>
            {isAdmin && (
              <button
                onClick={() => onEditCategory(category)}
                className="w-7.5 h-7.5 rounded-md border border-solid border-opacity-16 hover:border-opacity-100 hover:border-secondary border-dark cursor-pointer flex items-center justify-center bg-white text-dark text-opacity-70 hover:text-opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                data-testid={`edit-category-${category.id}`}
              >
                <PencilAltIcon className="w-4 h-4" />
              </button>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default ResourcesFilters
